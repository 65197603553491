import React from 'react'
import styled from 'styled-components'
import theme from 'stories/utils/theme'
import { Body2, ButtonText, Headline1, Headline3 } from 'stories/elements/Typography/Text'
import HeroBackground from 'assets/homepage/background-hero.webp'
import BottomHeroBackground from 'assets/homepage/bottom_background.webp'
import AustriaFlagIcon from 'assets/homepage/austria_flag.svg'
import GermanFlagIcon from 'assets/homepage/germany_flag.svg'
import FranceFlagIcon from 'assets/homepage/france_flag.svg'
import SwitzerlandFlagIcon from 'assets/homepage/switzerland_flag.svg'
import PolishFlagIcon from 'assets/homepage/polish_flag.svg'
import EnglishFlagIcon from 'assets/homepage/english_flag.svg'
import ItalyFlagIcon from 'assets/homepage/italy_flag.svg'
import SpainFlagIcon from 'assets/homepage/spain_flag.svg'
import ColombiaFlagIcon from 'assets/homepage/co_flag.svg'
import TurkeyFlagIcon from 'assets/homepage/turkey_flag.svg'
import UnitedStatesFlagIcon from 'assets/homepage/united_states_flag.svg'
import InterchangingTextComponent from 'components/modules/Homepage/InterchangingTextComponent'
import { NewButtonLink } from 'components/elements/NewButton'
import AnimationPlaceholder from 'assets/homepage/header_animation_placeholder.webp'
import VideoPlayer from 'components/modules/Players/VideoPlayer'
import useMedia from 'lib/hooks/useMedia'
import { deviceSize } from 'lib/constants/GridBreakpoints'
import { useRouter } from 'next/router'
import { getHeroVideo } from 'lib/constants/homepage'
import { knowCreateOptionsRoute } from 'lib/constants/routes'
import BackgroundAnimationWrapper from 'components/modules/Homepage/BackgroundAnimationWrapper'
import { KnowunityNumbers } from 'lib/constants/KnowunityNumbers'
import { localizeNumber, parseLocale } from 'lib/constants/localization'
import _useTranslation from 'lib/hooks/_useTranslation'
import SearchBarRedirect from '../App/widgets/SearchBarRedirect'
import { getCountryLanguageConfig } from 'lib/constants/countryLanguageConfig'

const HeaderContainer = styled.div`
    width: 100%;
    height: 100vh;
    min-height: 800px;
    background-color: ${theme.colors.deepSeaBlue};
    display: grid;
    overflow: hidden;
`

const MainContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    gap: 20px;

    a {
        z-index: ${theme.zIndex.FAB};
    }
    h1 {
        position: relative;
        z-index: ${theme.zIndex.topBanner};
        @media (max-width: 1050px) {
            width: 80%;
        }
    }
    margin-bottom: -25px;
`

const BottomHomePageStats = styled.div`
    width: 100%;
    display: grid;
    position: absolute;
    bottom: 5%;
    grid-template-columns: 1fr 1fr 300px 1fr 1fr;
    z-index: 5;
    @media (max-width: 1050px) {
        grid-template-columns: 1fr 300px 1fr;
    }
    p,
    h3 {
        color: ${theme.colors.white};
    }
    .tabler-spacer {
        @media (min-width: 1051px) {
            display: none;
        }
    }
    .desktop-spacer {
        @media (max-width: 1050px) {
            display: none;
        }
    }
`

interface FlagGridProps {
    isMobile?: boolean
}

export const FlagGrid = styled.div<FlagGridProps>`
    display: flex;
    gap: 10px;
    justify-content: center;
    svg {
        position: relative;
    }
    .flags {
        display: flex;
        max-width: 150px;
        flex-wrap: wrap;
        gap: 1px;
        justify-content: center;
    }
`

const FilterComponent = styled.img<{ isBottom?: boolean }>`
    position: absolute;
    width: 100%;
    height: ${({ isBottom }) => (isBottom ? 'auto' : '100vh')};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 0;
    z-index: 0;
    display: grid;
    overflow: hidden;
`

const SearchContainer = styled.div`
    width: 600px;
`

const HeaderComponent = () => {
    const { t } = _useTranslation('homepage')
    const md = useMedia(deviceSize.mdHeight)
    const fullHd = useMedia(deviceSize.fullHD)
    const { locale } = useRouter()
    const countryLanguageConfig = getCountryLanguageConfig(locale)
    const isLaunched = countryLanguageConfig?.isLaunched

    const parsedLocale = parseLocale(locale)

    const returnSize = (type: 'height' | 'width'): number => {
        const getWidth = type === 'width'
        if (md) {
            return getWidth ? 14 : 23.5
        } else if (fullHd) {
            return getWidth ? 22 : 40
        } else {
            return getWidth ? 16 : 27.8
        }
    }

    return (
        <div>
            <BackgroundAnimationWrapper
                src="homepage/homepage_hero_animation.json"
                loop
                autoPlay
                rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
                placeholderSrc={AnimationPlaceholder.src}
            />
            <HeaderContainer>
                <MainContentContainer>
                    <FilterComponent src={HeroBackground.src} alt="hero background fade" />
                    <FilterComponent src={BottomHeroBackground.src} alt="bottom hero background fade" isBottom />
                    <Headline1 textAlign="center" color={theme.colors.white} marginBottom={60} isREM>
                        {t('homepage/pageHeader')}
                        <br />
                        <InterchangingTextComponent t={t} />
                    </Headline1>

                    {!isLaunched ? (
                        <NewButtonLink
                            height={50}
                            width={215}
                            borderRadius={theme.borderRadius.normal}
                            type="primary"
                            fullWidth={false}
                            href={knowCreateOptionsRoute({ referrerScreen: 'homepage' })}
                        >
                            <ButtonText color={theme.colors.white} textAlign="center">
                                {t('homepage/expansionUploadCTA')}
                            </ButtonText>
                        </NewButtonLink>
                    ) : (
                        <SearchContainer>
                            <SearchBarRedirect referrerScreen="homepage_search" isLocatedOnHomepage />
                        </SearchContainer>
                    )}

                    <VideoPlayer
                        width={returnSize('width')}
                        height={returnSize('height')}
                        isREM
                        autoPlay
                        loop
                        muted
                        objectCover
                        sourceType="video/mp4"
                        showOverlay
                        border={`5px solid ${theme.colors.accentBlue}`}
                        source={getHeroVideo(parsedLocale.interfaceLanguageCode) ?? ''}
                    />
                </MainContentContainer>
                <BottomHomePageStats>
                    <div>
                        <Headline3 textAlign="center">
                            {t('homepage/statisticsStudentCount', {
                                ammount: localizeNumber(
                                    KnowunityNumbers.downloadMillionCount,
                                    parsedLocale.interfaceLanguageCode
                                ),
                            })}
                        </Headline3>
                        <Body2 textAlign="center">{t('homepage/statisticsStudentText')}</Body2>
                    </div>
                    <div className="tabler-spacer" />
                    <div>
                        <Headline3 textAlign="center">
                            {t('homepage/statisticsLearningMaterialsCount', {
                                ammount: KnowunityNumbers.knowThousandsCount,
                            })}
                        </Headline3>
                        <Body2 textAlign="center">{t('homepage/statisticsLearningMaterialsText')}</Body2>
                    </div>
                    <div className="desktop-spacer" />
                    <div>
                        <Headline3 textAlign="center">
                            {t('homepage/statisticsNewUsersCount', {
                                ammount: localizeNumber(KnowunityNumbers.newUsersWeekly, parsedLocale.interfaceLanguageCode),
                            })}
                        </Headline3>
                        <Body2 textAlign="center">{t('homepage/statisticsNewUsersText')}</Body2>
                    </div>
                    <div className="tabler-spacer" />
                    <div>
                        <FlagGrid>
                            <Headline3 textAlign="center">{KnowunityNumbers.activeCountryCount}</Headline3>
                            <div className="flags">
                                {[
                                    <GermanFlagIcon key="german-flag" />,
                                    <SwitzerlandFlagIcon key="switzerland-flag" />,
                                    <FranceFlagIcon key="france-flag" />,
                                    <AustriaFlagIcon key="austria-flag" />,
                                    <PolishFlagIcon key="polish-flag" />,
                                    <EnglishFlagIcon key="english-flag" />,
                                    <ItalyFlagIcon key="italy-flag" />,
                                    <SpainFlagIcon key="spain-flag" />,
                                    <UnitedStatesFlagIcon key="united-states-flag" />,
                                    <ColombiaFlagIcon key="colombian-flag" />,
                                    <TurkeyFlagIcon key="turkey-flag" />,
                                ].map((flag) => flag)}
                            </div>
                        </FlagGrid>
                        <Body2 textAlign="center">{t('homepage/statisticsCountriesOnlineText')}</Body2>
                    </div>
                </BottomHomePageStats>
            </HeaderContainer>
        </div>
    )
}

export default HeaderComponent
