import { TFunction } from 'i18next'
import { useState, useEffect } from 'react'
import { animated, useTransition } from 'react-spring'
import styled from 'styled-components'

export type Steps = 1 | 2 | 3

const AnimatedDiv = styled(animated.span)`
    height: auto;
    overflow: visible;
    position: relative;
    white-space: nowrap !important;
    left: 0;
    // Headline 1 line height is 88px
    right: 0;
    top: 3.5rem;
    margin: 0 auto;
    font-size: 3rem;
    font-weight: 900;
    text-align: center;
    white-space: pre-wrap;
    @media (max-width: 1050px) {
        top: 7rem;
    }
    @media (max-width: 750px) {
        top: 4rem; // Headline 1 line height on mobile is 2rem so => 4rem
        font-size: 1.3rem;
        font-weight: 700;
    }
    @media (min-width: 2048px) {
        font-size: 5rem;
        top: 5rem;
    }
`

interface Props {
    t: TFunction<'homepage'>
}

const InterchangingTextComponent = ({ t }: Props) => {
    const [count, setCount] = useState<Steps>(2)
    const transitions = useTransition([count], (item) => item, {
        from: {
            transform: `translate3d(0, 25%,0)`,
            opacity: 0,
            position: 'absolute',
        },
        enter: { transform: 'translate3d(0,0,0)', opacity: 1 },
        leave: {
            opacity: 0,
            transform: `translate3d(0, -25% ,0)`,
        },
    })

    useEffect(() => {
        setCount(1)
        const interval = setInterval(() => {
            setCount((count) => (count > 2 ? 1 : (++count as Steps)))
        }, 7000)

        return () => {
            clearInterval(interval)
        }
    }, [])

    const texts = {
        1: t(`homepage/pageHeaderInterchangingText1`),
        2: t(`homepage/pageHeaderInterchangingText2`),
        3: t(`homepage/pageHeaderInterchangingText3`),
    }

    return (
        <>
            {transitions.map(({ item, props }) => (
                <AnimatedDiv key={`interchaning-text-${item}-${Math.random()}`} style={props}>
                    {texts[item]}
                </AnimatedDiv>
            ))}
        </>
    )
}

export default InterchangingTextComponent
