/* eslint-disable @next/next/no-img-element */
import React from 'react'
import styled from 'styled-components'
import theme from 'stories/utils/theme'
import { useTranslation } from 'next-i18next'
import { Body1, Headline2 } from 'stories/elements/Typography/Text'
import AiTutorScreenshot1 from 'assets/homepage/ai_tutor_screenshot_1.webp'
import AiTutorScreenshot2 from 'assets/homepage/ai_tutor_screenshot_2.webp'
import AiTutorScreenshot3 from 'assets/homepage/ai_tutor_screenshot_3.webp'
import Sticker100 from 'assets/homepage/100_sticker.svg'
import ExclamationSticker from 'assets/homepage/exclamation_sticker.svg'

const Container = styled.div`
    width: 100%;
    min-height: fit-content;
    background-color: ${theme.colors.snowWhite};
    overflow: hidden;
    display: grid;
    gap: 50px;
    padding-bottom: 50px;
    position: relative;
    .screenshots {
        display: flex;
        flex-wrap: wrap;
        place-content: center;
        align-items: center;
        gap: 25px;
        padding: 10px 5%;
        @media (max-width: 750px) {
            scroll-padding-left: 25px;
            scroll-snap-points-x: repeat(325px);
            scroll-snap-type: x mandatory;
            overflow: scroll;
            flex-wrap: nowrap;
            place-content: start;
        }
    }

    @media (max-width: 750px) {
        min-height: auto;
        height: 800px;
        padding-left: 50px;
        padding-top: -50px;

        h2 {
            width: 100%;
            text-align: left;
            position: absolute;
            top: 15px;
            left: 15px;
            padding: 10px 25px 10px 50px;
        }
    }
`

const Screenshot = styled.div`
    width: fit-content;
    height: fit-content;
    position: relative;
    scroll-snap-align: start;
    @media (max-width: 750px) {
        top: 65px;
    }
`

const ScreenshotText = styled(Body1)<{ left: number; top: number; color?: string }>`
    position: absolute;
    left: ${({ left }) => left}px;
    top: ${({ top }) => top}px;
    max-width: 185px;
    color: ${({ color }) => color ?? theme.colors.white};
    font-family: Inter, sans-serif;
`

const BubbleText = styled(Body1)<{ left: number; top: number }>`
    position: absolute;
    left: ${({ left }) => left}px;
    top: ${({ top }) => top}px;
    width: fit-content;
    white-space: nowrap;
    font-size: 12px;
    color: ${theme.colors.placeholderText};
    z-index: 10;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    font-family: Inter, sans-serif;
    background-color: ${theme.colors.snowWhite};
    padding: 5px 10px;
    border-radius: ${theme.borderRadius.normal};
`

const StickerWrapper = styled.div<{ left: number; top: number }>`
    position: absolute;
    left: ${({ left }) => left}px;
    top: ${({ top }) => top}px;
    width: fit-content;
    height: fit-content;
    @media (max-width: 900px) {
        display: none;
    }
`

const AITutorComponent = () => {
    const { t } = useTranslation('homepage')
    const { t: tCommon } = useTranslation('common')

    return (
        <Container>
            <Headline2 color={theme.colors.accentBlue} textAlign="center">
                {t('homepage/aiTutorSectionHeader')}
            </Headline2>
            <div className="screenshots">
                <Screenshot>
                    <StickerWrapper left={-50} top={450}>
                        <ExclamationSticker />
                    </StickerWrapper>
                    <img
                        src={AiTutorScreenshot1.src}
                        width={AiTutorScreenshot1.width}
                        height={AiTutorScreenshot1.height}
                        alt=""
                    />
                    <BubbleText left={-100} top={85}>
                        {t('homepage/aiTutorBenefit1')}
                    </BubbleText>
                    <ScreenshotText left={50} top={60} fontSize={12}>
                        {t('homepage/aiTutorCustomizationScreenshotTitle')}
                    </ScreenshotText>
                    <ScreenshotText left={50} top={130}>
                        {t('homepage/aiTutorCustomizationScreenshotSubtitle')}
                    </ScreenshotText>
                    <ScreenshotText left={115} top={421}>
                        {t('homepage/aiTutorCustomizationScreenshotBotName')}
                    </ScreenshotText>
                    <ScreenshotText left={95} top={465} fontSize={12} fontWeigth={400}>
                        {t('homepage/aiTutorCustomizationScreenshotBotDescription')}
                    </ScreenshotText>
                    <ScreenshotText left={40} top={565} color={theme.colors.white} fontSize={14}>
                        {tCommon('common/goBack')}
                    </ScreenshotText>
                    <ScreenshotText left={180} top={565} color={theme.colors.headlineDark} fontSize={14}>
                        {tCommon('common/continue')}
                    </ScreenshotText>
                </Screenshot>
                <Screenshot>
                    <img
                        src={AiTutorScreenshot2.src}
                        width={AiTutorScreenshot2.width}
                        height={AiTutorScreenshot2.height}
                        alt=""
                    />
                    <BubbleText left={-80} top={230}>
                        {t('homepage/aiTutorBenefit2')}
                    </BubbleText>
                    <BubbleText left={200} top={360}>
                        {t('homepage/aiTutorBenefit3')}
                    </BubbleText>
                    <ScreenshotText left={30} top={95} fontSize={12}>
                        {t('homepage/aiTutorFeedbackSubtitle')}
                    </ScreenshotText>
                    <ScreenshotText left={30} top={130}>
                        {t('homepage/aiTutorFeedbackQuestion')}
                    </ScreenshotText>
                    <ScreenshotText left={105} top={485}>
                        {t('homepage/aiTutorFeedbackAnswer')}
                    </ScreenshotText>
                </Screenshot>
                <Screenshot>
                    <img
                        src={AiTutorScreenshot3.src}
                        width={AiTutorScreenshot3.width}
                        height={AiTutorScreenshot3.height}
                        alt=""
                    />
                    <StickerWrapper left={230} top={-65}>
                        <Sticker100 />
                    </StickerWrapper>
                    <BubbleText left={240} top={263}>
                        {t('homepage/aiTutorBenefit4')}
                    </BubbleText>
                    <ScreenshotText left={55} top={50}>
                        {t('homepage/aiTutorExampleTitle')}
                    </ScreenshotText>
                    <ScreenshotText left={75} top={183} fontSize={14}>
                        {t('homepage/aiTutorExampleIntroduction')}
                    </ScreenshotText>
                    <ScreenshotText left={90} top={345} fontSize={14} style={{ width: 150 }}>
                        {t('homepage/aiTutorExampleEquations')}
                    </ScreenshotText>
                    <ScreenshotText left={80} top={470} style={{ color: theme.colors.headlineDark }} textAlign="center">
                        {t('homepage/aiTutorExampleContinueLearning')}
                    </ScreenshotText>
                    <ScreenshotText left={120} top={520} textAlign="center">
                        {t('homepage/aiTutorExampleNotNowButton')}
                    </ScreenshotText>
                    <ScreenshotText left={165} top={527} color={theme.colors.headlineDark} fontSize={15}>
                        {tCommon('common/continue')}
                    </ScreenshotText>
                    <ScreenshotText left={165} top={585} color={theme.colors.white} fontSize={14}>
                        {tCommon('common/goBack')}
                    </ScreenshotText>
                </Screenshot>
            </div>
        </Container>
    )
}

export default AITutorComponent
